/**
 * Code dédié à l'implémentation du plan de marquage fourni par Intuiti
 */

$(document).ready(function(){

  /** Slideshow */
  $("[data-gtm='slideshow']").on('click', function () {
    dataLayer.push({
      "event": "Home",
      "CTA": "slideshow",
    });
  });

  /** Bouton "Au tableau" */
  $("[data-gtm='btn-tableau']").on('click', function () {
    dataLayer.push({
      "event": "Au Tableau",
      "CTA": "body",
    });
  });

  /** Bouton "Voir toutes les recettes" */
  $("[ data-gtm='see-all-recipes']").on('click', function () {
    dataLayer.push({
      "event": "Voir Recettes",
      "CTA": "body",
    });
  });

  /** Bouton "Découvrez tous nos produits" */
  $("[data-gtm='see-all-products']").on('click', function () {
    dataLayer.push({
      "event": "Voir Produits",
      "CTA": "body",
    });
  });

  /** Bouton "Découvrez tous nos articles" */
  $("[data-gtm='see-all-articles']").on('click', function () {
    dataLayer.push({
      "event": "Voir Articles",
      "CTA": "body",
    });
  });

  /** Bouton de recherche */
  $("[data-gtm='search']").on('click', function () {
    dataLayer.push({
      "event":"VirtualPageview",
      "virtualURL":"/rechercher/step1",
      "virtualTitle" : "Que recherchez-vous ? | Soignon"
    });
    dataLayer.push({
      "event": "Recherche",
      "CTA": "sticky",
    });
  });

  /** Bouton de validation - Formulaire contact */
  $("[data-gtm='contact-submit']").on('click', function () {
    dataLayer.push({
      "event": "Contact",
      "CTA": "valider",
    });
  });

  /** Sélecteur "Je précise ma demande" - Formulaire de contact */
  $("[data-gtm='contact-subject']").on('change', function () {
    dataLayer.push({
      "event": "Formulaire",
      "Demande": $(this).select2('data')[0].text,
    });
  });

  /** Bouton page suivante - page liste recette */
  $(".recipe-category-list .page-item--next .btn").attr('data-gtm', 'next-page');

  $("[data-gtm='next-page']").on('click', function () {
    dataLayer.push({
      "event": "Page suivante",
      "CTA": "body",
    });
  });

  /** Sélecteur "J'affine ma recherche..." - page Catégorie de recette */
  $(".subcategories--recipe").on('click', ".subcategory__item.active[data-gtm='recipe-type']", function () {
    dataLayer.push({
      "event": "Recherche",
      "Type_Recette": $(this)[0].innerText,
    });
  });

  /** Zone pour déplier une réponse - FAQ */
  $("[data-gtm='faq-question']").on('click', function () {
    dataLayer.push({
      "event": "FAQ",
      "Question": $(this)[0].innerText,
    });
  });

  /** Notation avis : "Alors c'était bon ?" - page détail recette */
  $(".hearts__list").on('click', ".selected[data-gtm='recipe-rating']", function () {
    dataLayer.push({
      "event": "Avis Recette",
      "Note": 'Note ' + $(this).data('value'),
    });
  });

  /** Bouton de partage FB */
  $("[data-gtm='fb-share']").on('click', function () {
    dataLayer.push({
      "event": "Partage Facebook",
      "CTA": "body",
    });
  });

  /** Bouton de partage FB - sticky bar */
  $("[data-gtm='fb-share-sticky']").on('click', function () {
    dataLayer.push({
      "event": "Partage Facebook",
      "CTA": "sticky",
    });
  });

  /** Bouton de partage Pinterest */
  $("[data-gtm='pinterest-share']").on('click', function () {
    dataLayer.push({
      "event": "Partage Pinterest",
      "CTA": "body",
    });
  });

  /** Bouton de partage Pinterest - sticky bar */
  $("[data-gtm='pinterest-share-sticky']").on('click', function () {
    dataLayer.push({
      "event": "Partage Pinterest",
      "CTA": "sticky",
    });
  });

  /** Bouton de partage impression */
  $("[data-gtm='print-share']").on('click', function () {
    dataLayer.push({
      "event": "Partage Impression",
      "CTA": "body",
    });
  });

  /** Bouton de partage impression - sticky bar */
  $("[data-gtm='print-share-sticky']").on('click', function () {
    dataLayer.push({
      "event": "Partage Impression",
      "CTA": "sticky",
    });
  });

  /** Bouton de partage Twitter */
  $("[data-gtm='twitter-share']").on('click', function () {
    dataLayer.push({
      "event": "Partage Twitter",
      "CTA": "body",
    });
  });

  /** Bouton de partage Twitter - sticky bar */
  $("[data-gtm='twitter-share-sticky']").on('click', function () {
    dataLayer.push({
      "event": "Partage Twitter",
      "CTA": "sticky",
    });
  });

  /** Bouton de partage de lien */
  $("[data-gtm='link-share']").on('click', function () {
    dataLayer.push({
      "event": "Partage Link",
      "CTA": "body",
    });
  });

  /** Bouton de partage de lien - sticky bar */
  $("[data-gtm='link-share-sticky']").on('click', function () {
    dataLayer.push({
      "event": "Partage Link",
      "CTA": "sticky",
    });
  });



});
